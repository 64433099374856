export const routes = {
  root: "/",
  participants: "/hvem-skal-med",
  travelDates: "/hvornår",
  tickets: "/mine-billetter",
  faq: "/faq",
  packingList: "/hvad-skal-jeg-pakke",
  meetingLocation: "/hvor-mødes-vi",
  activityPlan: "/hvad-skal-vi-lave",
};
