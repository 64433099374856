import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Participants } from "./components/routes/Participants";
import Root from "./components/Root";
import ErrorPage from "./components/ErrorPage";
import Faq from "./components/routes/Faq";
import { TravelDates } from "./components/routes/TravelDates";
import { routes } from "./components/routes/routes";
import { PackingList } from "./components/routes/PackingList";
import { Activities } from "./components/routes/Activities";
import { MeetingLocation } from "./components/routes/MeetingLocation";

// Todo

// Hvor mødes vi?
// Tidsplan (liste af activities med tid/sted/osv.)
// "Hvor skal vi bo" --> billeder af/links til random motels, indtil ankomst i BG
// Filer med rigtige billetter (fra backend)

const router = createBrowserRouter([
  {
    path: routes.root,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: routes.root,
        element: <Faq />,
      },
      {
        path: routes.participants,
        element: <Participants />,
      },
      {
        path: routes.travelDates,
        element: <TravelDates />,
      },
      {
        path: routes.activityPlan,
        element: <Activities />,
      },
      {
        path: routes.faq,
        element: <Faq />,
      },
      {
        path: routes.packingList,
        element: <PackingList />,
      },
      {
        path: routes.meetingLocation,
        element: <MeetingLocation />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
