import { useState } from "react";

export type GentlemanValues = {
  codeName: string;
};

export const localStorageKey: string = "gentleman";
export function useGentlemanStorage() {
  const [values] = useLocalStorage<GentlemanValues>(localStorageKey, {
    codeName: "",
  });
  return values;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): readonly [T, (value: T | ((val: T) => T)) => void] {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (typeof window !== "undefined") {
        const item = window?.localStorage.getItem(key);
        return item && item !== "undefined" ? JSON.parse(item) : initialValue;
      }
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        const valueString = valueToStore ? JSON.stringify(valueToStore) : "";
        window?.localStorage.setItem(key, valueString);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export function getFromLocalStorage<T>(key: string): T | undefined {
  try {
    if (typeof window !== "undefined") {
      const item = window?.localStorage.getItem(key);
      return item && item !== "undefined" ? JSON.parse(item) : undefined;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
}
