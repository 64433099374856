import { useParticipants } from "../../api/participants";
import { Card, CardContainer } from "../card";

export function Participants() {
  const { participants } = useParticipants();
  return (
    <CardContainer>
      {participants.map((p) => (
        <Card colorKey={p.colorKey} key={p.codeName}>
          {p.name}
        </Card>
      ))}
    </CardContainer>
  );
}
