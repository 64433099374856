import { Card, CardContainer } from "../card";
import { BeerSeparator } from "../common/BeerSeparator";

export function TravelDates() {
  return (
    <CardContainer>
      <Card>
        <h2>Afsted</h2>
        <div>✈</div>
        <div>Torsdag d. 28. september 2023</div>
      </Card>
      <BeerSeparator />
      <Card>
        <h2>Hjem</h2>
        <div>🏠</div>
        <div>Søndag d. 1. oktober 2023</div>
        <div>Vi ankommer til København omkring kl. 20.</div>
      </Card>
    </CardContainer>
  );
}
