import React from "react";

interface FormattedDateProps {
  date?: Date;
  format?: DateFormat;
}

export const FormattedDate: React.FunctionComponent<FormattedDateProps> = ({
  date,
  format,
}) => {
  return <span>{date ? formatDate(date, format) : "-"}</span>;
};

interface FormattedDayProps {
  date?: Date;
}

export const FormattedDay: React.FunctionComponent<FormattedDayProps> = ({
  date,
}) => {
  return <span>{date ? formatDay(date) : "-"}</span>;
};

function formatDay(date: Date) {
  const d = new Date(date);
  const dayIndex = d.getDay();
  return daysOfWeek[dayIndex];
}

const daysOfWeek = [
  'Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'
]

export function formatDate(date: Date, format?: DateFormat): string {
  const d = new Date(date);
  const m = d.getMonth() + 1;
  const month = m > 9 ? m : `0${m}`;
  const dayOfMonth = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;

  if (format === "Mon dd yyyy") {
    const monthAbbrev = monthAbbreviations[m - 1];
    return `${monthAbbrev} ${dayOfMonth}, ${d.getFullYear()}`;
  }

  return `${d.getFullYear()}-${month}-${dayOfMonth}`;
}

export type DateFormat = "yyyy-mm-dd" | "Mon dd yyyy";

const monthAbbreviations = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
