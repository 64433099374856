import { Card, CardContainer } from "../card";

const packingListItems = [
  "Pas",
  "Sko til lange gåture",
  "Badeshorts",
  "Træningstøj",
  "'Gå i byen'-tøj",
  "Tøj til 10-20°",
];

export function PackingList() {
  return (
    <CardContainer>
      <Card>
        <h2>Du må pakke</h2>
        <ul>
          <li>
            🎒 1 taske på maks. 8 kg (55 x 40 x 23 cm)
          </li>
          <li>
            👜 1 taske på maks. 4 kg (40 x 30 x 20 cm)
          </li>
        </ul>
      </Card><Card>
        <h2>Du skal huske</h2>
        <ul>
          {packingListItems.map((item) => (
            <li key={item}>👉 {item}</li>
          ))}
        </ul>
      </Card>
    </CardContainer>
  );
}
