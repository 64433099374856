import { Link } from "react-router-dom";
import { ClickableCard } from "./card";

interface MenuItemProps {
  to: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const MenuItem = ({ to, children }: MenuItemProps) => {
  return (
    <Link to={to} className="w-full">
      <ClickableCard>{children}</ClickableCard>
    </Link>
  );
};
