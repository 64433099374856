import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  GentlemanValues,
  localStorageKey,
  useLocalStorage,
} from "./useLocalStorage";

// Looks for a search param in the current url with the given paramName.
// Applies the value of that search param as codeName in our localStorage.
export function useApplyCodeToLocalStorage(paramName: string) {
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const searchParamValue = searchParams[paramName];
  const searchParamValueString = getSearchParamString(searchParamValue);
  const [value, setValue] = useLocalStorage<GentlemanValues>(localStorageKey, {
    codeName: "",
  });

  useEffect(() => {
    if (searchParamValueString && value.codeName !== searchParamValueString) {
      setValue({
        codeName: searchParamValueString ?? "",
      });
    }
  }, [searchParamValueString, value.codeName, setValue]);
}

export function getSearchParamString(
  value: string | (string | null)[] | null
): string | undefined {
  if (!value || Array.isArray(value)) {
    return undefined;
  }

  return value;
}
