import styled from "styled-components";
import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <>
      <AppContainer>
        <Outlet />
      </AppContainer>
    </>
  );
}

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-bottom: 100px;
`;
