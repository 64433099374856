import React from "react";
import { Card } from "../card";
import { FormattedTime } from "../common/FormattedTime";
import { Activity } from "./api/useActivities";

export type ActivityListProps = {
    activities: Activity[];
}

export function ActivityList({ activities }: ActivityListProps) {
    return (
        <React.Fragment>
            {activities.map(a => <ActivityListItem activity={a} key={a.startsAt.toString()} />)}            
        </React.Fragment>
    );
  }
  

export type ActivityListItemProps = {
  activity: Activity;
};
export function ActivityListItem({ activity }: ActivityListItemProps) {
  return (
    <Card>
      <ActivityContent activity={activity} />
    </Card>
  );
}

function ActivityContent({ activity }: ActivityListItemProps) {
    return (
    <div className="flex w-full flex-col">
      <h2>
        <span>{activity.url ? <a href={activity.url} rel="noopener noreferrer" target="_blank">{activity.name}</a> : activity.name}</span> (<FormattedTime date={activity.startsAt} />{activity.endsAt && <> - <FormattedTime date={activity.endsAt} /></>})
      </h2>
      <InfoItem>{activity.description}</InfoItem>
      {activity.items && activity.items.map(item => (
        <div className="w-full" key={item.name}>
          <span className="mr-1"><span className="font-bold">{item.url ? <a href={item.url} rel="noopener noreferrer" target="_blank">{item.name}</a> : item.name}</span>{item.distance ? <span className="ml-1">({item.distance} fra hotellet)</span> : null}:</span>
          <span className="">{item.description}</span>
        </div>
      ))}
    </div>
  );
}

type InfoItemProps = {
  children: React.ReactNode;
};
function InfoItem({ children }: InfoItemProps) {
  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="font-bold">{children}</div>
    </div>
  );
}

