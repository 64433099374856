import React, { useMemo } from "react";
import styles from "./card.module.css";

type CardProps = {
  children?: React.ReactNode;
  colorKey?: ColorKey;
  className?: string;
};

type ClickableCardProps = CardProps & {};
export const ClickableCard = ({ className, ...rest }: ClickableCardProps) => {
  const classNames = className
    ? `${className} hover:border-black`
    : "hover:border-black";
  return <Card {...rest} className={classNames} />;
};

const colorKeys = ["bg1", "bg2", "bg3", "bg4", "bg5", "bg6"];
export type ColorKey = (typeof colorKeys)[number];
export const Card = ({ children, colorKey, className }: CardProps) => {
  const colorClass = useMemo(
    () => (colorKey ? styles[colorKey] : getRandomColor()),
    [colorKey]
  );

  return (
    <div
      className={`card w-full max-w-full border-4 ${colorClass} ${
        className ? className : ""
      }`}
    >
      <div className="card-body bg-white bg-opacity-50 text-black justify-center items-center text-lg rounded-xl">
        {children}
      </div>
    </div>
  );
};

function getRandomColor() {
  const rand = Math.random();
  const idx = Math.floor(rand * colorKeys.length);
  return styles[colorKeys[idx]];
}
