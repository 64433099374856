import { useEffect, useState } from "react";
import { ColorKey } from "../components/card";

export function useParticipants() {
  const [participants, setParticipants] = useState<Participant[]>([]);
  useEffect(() => {
    const result = getParticipants();
    setParticipants(result);
  }, []);

  return { participants };
}

function getParticipants(): Participant[] {
  return participants;
}

type Participant = {
  name: string;
  codeName: string;
  colorKey: ColorKey;
};
const participants: Participant[] = [
  {
    name: "Carsten",
    codeName: "Mogens Langballe",
    colorKey: "bg1",
  },
  {
    name: "Daniel F",
    codeName: "Fiske Franz",
    colorKey: "bg2",
  },
  {
    name: "Daniel S",
    codeName: "Magic Mike",
    colorKey: "bg3",
  },
  {
    name: "Michael",
    codeName: "Didrik Due",
    colorKey: "bg4",
  },
  {
    name: "Rasmus",
    codeName: "Johnny Cologne",
    colorKey: "bg5",
  },
  {
    name: "Anders",
    codeName: "Amagermanden",
    colorKey: "bg6",
  },
];
