import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: ErrorMessage = useRouteError() as ErrorMessage;
  console.error(error);

  return (
    <div id="error-page">
      <h1>Hov!</h1>
      <p>Det fikser jeg lige...</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

type ErrorMessage = {
  statusText: string | undefined;
  message: string | undefined;
};
