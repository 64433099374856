interface CardContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const CardContainer = ({ children, className }: CardContainerProps) => {
  const classNames = className
    ? `${className} max-w-screen-lg w-full h-full flex flex-col justify-start items-center space-y-2 p-2`
    : "max-w-screen-lg w-full h-full flex flex-col justify-start items-center space-y-2 p-2";
  return <div className={classNames}>{children}</div>;
};
