import React from "react";
import { CardContainer } from "../card";
import { useActivities } from "../activities/api/useActivities";
import { SleepSeparator } from "../common/SleepSeparator";
import { ActivityList } from "../activities/ActivityList";
import { FormattedDay } from "../common/FormattedDate";

export function Activities() {
  const { data: activities } = useActivities();

  const sorted = (activities ?? []).sort((a, b) => a.startsAt > b.startsAt ? 1 : -1);
  const grouped = groupBy(sorted.map((a) => {
    const activityDate = a.activityDate ? new Date(a.activityDate) : new Date(a.startsAt);
    const month = activityDate.getMonth();
    const date = activityDate.getDate();
    const formattedActivityDate = `${month}-${date}`;
    return { ...a, formattedActivityDate }
  }), a => a.formattedActivityDate);

  const groupKeys = Array.from( grouped.keys() );
  
  return (
      <CardContainer className="h-auto">
        {groupKeys
          .map((groupKey, idx) => {
            const activities = grouped.get(groupKey) ?? [];
            const date = activities[0].startsAt;
            return (
                <React.Fragment key={groupKey}>
                  {idx > 0 && <SleepSeparator />}
                  <h2><FormattedDay date={date} /></h2>
                  <ActivityList activities={activities} />
                </React.Fragment>
              )
          })}
      </CardContainer>
  );
}

function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map<K, Array<V>>();
    list.forEach((item: any) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}