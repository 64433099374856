import { getApiBase } from "../../../api/getApiBase";
import { useGentlemanStorage } from "../../../utils/hooks/useLocalStorage";
import { useQuery, UseQueryResult } from "react-query";

async function getActivities(travelerName: string): Promise<Activity[]> {
  const url = new URL("/api/GetActivities", getApiBase());
  url.searchParams.append("travelerName", travelerName);
  const result = await fetch(url.href);
  const activities = (await result.json()) as Activity[];
  return activities;
}

export const getActivitiesQueryKey = "getActivities";
export function useActivities(): UseQueryResult<Activity[], unknown> {
  const { codeName } = useGentlemanStorage();

  const query = useQuery(getActivitiesQueryKey, () => getActivities(codeName));
  return query;
}

export type Activity = {
    startsAt: Date;
    endsAt: Date | null;
    name: string;
    description: string;
    url: string | null;
    items: ActivityItem[] | null;
    activityDate: Date | null;
};

export type ActivityItem = {
  name: string;
  description: string;
  url: string | null;
  distance: string | null;
}
