import { Card, CardContainer } from "../card";

export function MeetingLocation() {
  return (
    <CardContainer>
      <Card>
        <h2>Vi mødes...</h2>
        <div>Torsdag d. 28. september 2023</div>
        <div>Kl. <strong>12.00</strong></div>
        <div>Ved <strong>HCAB/Daniel</strong></div>
      </Card>
    </CardContainer>
  );
}
