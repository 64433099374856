import React from "react";

interface FormattedTimeProps {
  date?: Date;
}

export const FormattedTime: React.FunctionComponent<FormattedTimeProps> = ({
  date,
}) => {
  return <span>{date ? formatTime(date) : "-"}</span>;
};

export function formatTime(date: Date): string {
  const d = new Date(date);
  const h = d.getHours();
  const hours = h > 9 ? h : `0${h}`;
  const m = d.getMinutes();
  const minutes = m > 9 ? m : `0${m}`;

  return `${hours}:${minutes}`;
}
