import { useApplyCodeToLocalStorage } from "../../utils/hooks/useCodeApplier";
import { CardContainer } from "../card";
import { MenuItem } from "../Menu";
import { routes } from "./routes";

function Faq() {
  useApplyCodeToLocalStorage("code");

  return (
    <CardContainer>
      <MenuItem to={routes.participants}>Hvem skal med?</MenuItem>
      <MenuItem to={routes.travelDates}>Hvornår er det?</MenuItem>
      {/* <MenuItem to={routes.tickets}>Hvor er mine billetter?</MenuItem> */}
      {/* <MenuItem to="/hvor-skal-vi-hen">Hvor skal vi hen?</MenuItem> */}
      <MenuItem to={routes.packingList}>Hvad skal jeg pakke?</MenuItem>
      <MenuItem to={routes.meetingLocation}>Hvor mødes vi?</MenuItem>
      <MenuItem to={routes.activityPlan}>Hvad skal vi lave?</MenuItem>
    </CardContainer>
  );
}

// Add draft for activities
// Hide tickets for now


export default Faq;
